export default function () {
  const dequalifyTitle = (title: string | undefined | null) => {
    if (!title) {
      return ''
    }
    const qualifications = [
      'Statement of Attainment in',
      'TAFE Statement in',
      'Certificate in',
      'Certificate I in',
      'Certificate II in',
      'Certificate III in',
      'Certificate IV in',
      'Undergraduate Certificate in',
      'Graduate Certificate in',
      'Diploma of',
      'Advanced Diploma of',
      'Associate Degree in',
      'Graduate Diploma of',
      'Bachelor of',
      'Masters of'
    ]
    const substring = qualifications.findIndex((qual) => title.startsWith(qual))
    return title.startsWith(qualifications[substring])
      ? title.replace(qualifications[substring], '').trim()
      : title
  }

  return {
    dequalifyTitle
  }
}
